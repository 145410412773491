import mediumZoom from 'medium-zoom'

const form = document.querySelector("#search-form");
const guestForm = document.querySelector("#search-form-guest");
const button = document.querySelector('#search-button');
const results = document.querySelector("#results");
const resultTemplate = document.querySelector("#result-template");

// mediumZoom('[data-zoomable]', {
// 	margin: 100,
// 	background: 'rgba(0, 0, 0, 0.8)'
// })

const getHeadoutSearch = async (query) => {
	const headoutSearchBaseUrl = "https://api.headout.com/api/v2/search/products";
	const params = `?q=${encodeURIComponent(query)}&currency=USD&offset=0&limit=2&language=EN`
	const response = await fetch(headoutSearchBaseUrl + params);
	const data = await response.json();
	return data.pageData.items
}


const getHeadoutReviewMedia = async (tgid) => {
	const headoutReviewBaseUrl = "https://api.headout.com/api/v6/tour-groups";
	const params = `/${encodeURIComponent(tgid)}/review-medias?language=EN&limit=20&offset=0`
	const response = await fetch(headoutReviewBaseUrl + params);
	const data = await response.json();

	return data.items
}

const addResultsForImages = (images) => {
	let newResults = []

	images.map(img => {
		const imageEl = resultTemplate.content.cloneNode(true)
		imageEl.querySelector('.result-image').src = img.url + "?w=400"; // Low-rez for faster loading in thumbnails
		imageEl.querySelector('.result-image').dataset.zoomSrc = img.url + "?w=1600";
		imageEl.querySelector('.result-description').innerText = img.description;

		// TODO: Add these in later
		// imageEl.querySelector('.result-url').innerText = img.url;

		newResults.push(imageEl)
	})

	results.replaceChildren(...newResults)
}

const addResultsForReviewImages = (images) => {
	let newResults = []

	images.map(img => {
		const imageEl = resultTemplate.content.cloneNode(true)
		imageEl.querySelector('.result-image').src = img + "?w=400&h=250"; // Low-rez for faster loading in thumbnails
		imageEl.querySelector('.result-image').dataset.zoomSrc = img + "?w=1600&h=1000";
		// imageEl.querySelector('.result-description').innerText = img.description;

		// TODO: Add these in later
		// imageEl.querySelector('.result-url').innerText = img.url;

		newResults.push(imageEl)
	})

	results.replaceChildren(...newResults)
}

const down = (e) => {
	if (e.key === "k" && e.metaKey) {
		document.querySelector("#search-form > input").focus()
	}
}

document.addEventListener("keydown", down)

if (form) {
	form.addEventListener("submit", async (e) => {
		e.preventDefault()

		const query = document.querySelector("#search-form > input").value;
		if (!query) return

		button.classList.toggle('loading')

		// TEMP until I get better analytics in-place.
		fetch("https://submit-form.com/N8P3QOGp", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				message: query,
			}),
		})
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.error(error);
			});

		// TODO: for future improvement when I have time.

		// if query is Headout Page URL, get the identifier from slug and call that API
		// if query is number, find it's identifier and call Tour Group API / Collection API
		// if query is plain text string, call Search API as below

		const results = await getHeadoutSearch(query);

		let images = []
		await results.map(r => {
			if (r.media.productImages.length > 0) {
				images = [...r.media.productImages]
			}
		})

		addResultsForImages(images)
		button.classList.toggle('loading')

		mediumZoom('[data-zoomable]', {
			margin: 100,
			background: 'rgba(0, 0, 0, 0.8)'
		})
	});
}


if (guestForm) {
	guestForm.addEventListener("submit", async (e) => {
		e.preventDefault()

		const tgid = document.querySelector("#search-form-guest > input").value;
		if (!tgid) return

		button.classList.toggle('loading')

		// TEMP until I get better analytics in-place.
		fetch("https://submit-form.com/N8P3QOGp", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				message: tgid,
			}),
		})
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.error(error);
			});

		// TODO: for future improvement when I have time.

		// if query is Headout Page URL, get the identifier from slug and call that API
		// if query is number, find it's identifier and call Tour Group API / Collection API
		// if query is plain text string, call Search API as below

		const results = await getHeadoutReviewMedia(tgid);

		let images = []
		await results.map(r => {
			if (r.reviewMedias.length > 0) {
				images = [...images, ...r.reviewMedias.map(m => m.url)]
			}
		})

		// console.log(results)

		// console.log(images)

		addResultsForReviewImages(images)
		button.classList.toggle('loading')

		mediumZoom('[data-zoomable]', {
			margin: 100,
			background: 'rgba(0, 0, 0, 0.8)'
		})
	});
}